<template>
  <!-- Leaderboard Section -->
  <section class="h-auto leaderboard-bg">
    <div class="flex justify-center items-center px-4 md:px-8 lg:pt-0" id="leaderboard">
      <div
        class="flex flex-col justify-center items-center my-8 md:my-10 p-8 rounded-3xl">
        <div class="flex flex-row justify-center items-center">
          <!-- <img v-motion-pop-visible src="../assets/leaderboard.png" class="px-5" alt="leaderboard"> -->
        </div>
        <!--
        <div v-motion-pop-visible class="flex justify-center text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-10 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">roobet leaderboard ends every week. <br> ONLY THE TOP 15 ARE REWARDED. YOU WILL BE REWARDED VIA ROOBET <br> SO MAKE SURE TO SIGN UP UNDER CODE: MIK</div>
        <div v-motion-pop-visible class="text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-2 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">All wagers are counted during the inclusive dates:
              <span class="text-white">{{ roobetStartDate }} - {{ roobetEndDate }}</span>
              <br>Leaderboard ends every Sunday 11: 59:59PM EST. 
              <br>New leaderboard data will be available by Monday 12:00 AM EST
        </div> -->
        <div v-if="loading == true" class="text-center text-white text-xl md:text-4xl mt-10 font-bold" style="font-family: Secular One, sans-serif;">Loading Leaderboard...</div>
        <div v-if="loading == false"  class="hidden md:block">
          <div class="flex flex-row justify-center items-center gap-6 lg:gap-24 mt-32">
            <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
              <div class="bg-gradient-to-b from-[#443F3F] via-[#FFFDF6] to-[#443F3F] p-2 rounded-3xl min-w-[210px]">
                 <div class="flex flex-col justify-center items-center bg-gradient-to-b from-[#121212] via-[#161045] to-[#251F1F] px-6 py-4 rounded-3xl">
                    <img src="../assets/2nd-banner.png" class="absolute inset-x-0 top-0 -mt-[75px] -ml-4 w-[200px]" alt="2nd-banner">
                    <div class="flex flex-col justify-center items-center mt-20">
                      <p class="text-white text-[25px]" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? maskString(top3[1].user_name) : '' }}</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">Wagered:</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? top3[1].wagered : '' }}</p>
                      <p class="text-black text-4xl bg-gradient-to-r from-[#d763e1c3] via-[#EE2FFF] to-[#cf00e1c3] text-transparent bg-clip-text" style="font-family: Russo One, system-ui; -webkit-text-stroke: white; -webkit-text-stroke-width: 0.50px;">
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                 </div>
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center -mt-40 w-60">
              <div class="bg-gradient-to-b from-[#644E00] via-[#F4BF00] to-[#644E00] p-2.5 rounded-3xl min-w-[210px]">
                 <div class="flex flex-col justify-center items-center bg-gradient-to-b from-[#121212] via-[#161045] to-[#251F1F] px-6 py-4 rounded-3xl">
                    <img src="../assets/1st-banner.png" class="absolute inset-x-0 top-0 -mt-[100px] -ml-10" alt="1st-banner">
                    <div class="flex flex-col justify-center items-center mt-20">
                      <p class="text-white text-[25px]" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? maskString(top3[0].user_name) : '' }}</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">Wagered:</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? top3[0].wagered : '' }}</p>
                      <p class="text-black text-4xl bg-gradient-to-r from-[#d763e1c3] via-[#EE2FFF] to-[#cf00e1c3] text-transparent bg-clip-text" style="font-family: Russo One, system-ui; -webkit-text-stroke: white; -webkit-text-stroke-width: 0.50px;">
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                 </div>
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
              <div class="bg-gradient-to-b from-[#361A00] via-[#C86000] to-[#361A00] p-2 rounded-3xl min-w-[210px]">
                 <div class="flex flex-col justify-center items-center bg-gradient-to-b from-[#121212] via-[#161045] to-[#251F1F] px-6 py-4 rounded-3xl">
                    <img src="../assets/3rd-banner.png" class="absolute inset-x-0 top-0 -mt-[80px] -ml-4 w-[200px]" alt="3rd-banner">
                    <div class="flex flex-col justify-center items-center mt-20">
                      <p class="text-white text-[25px]" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? maskString(top3[2].user_name) : '' }}</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">Wagered:</p>
                      <p class="text-white text-lg uppercase" style="font-family: Russo One, system-ui;">{{ top3.length > 0 ? top3[2].wagered : '' }}</p>
                      <p class="text-black text-4xl bg-gradient-to-r from-[#d763e1c3] via-[#EE2FFF] to-[#cf00e1c3] text-transparent bg-clip-text" style="font-family: Russo One, system-ui; -webkit-text-stroke: white; -webkit-text-stroke-width: 0.50px;">
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                 </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-center items-center lg:-mt-16">
            <div class="bg-gradient-to-r from-[#150033] via-[#461B83] to-[#150033] p-1 rounded-full">
              <div v-motion-slide-visible-bottom class="text-center text-2xl text-white font-thin uppercase rounded-full px-10 py-3 bg-gradient-to-r from-[#121212] via-[#DC00B9] to-[#121212]" style="font-family: Lalezar, system-ui;">
                {{ selectedLeaderboard == false ? timer : "Leaderboard Ended" }}
              </div>
            </div>
            <div v-if="showPrevLeaderboard == 'true'" class="hidden md:block">
              <label class="toggle-switch mt-8 bg-gradient-to-r from-[#d763e1c3] via-[#EE2FFF] to-[#cf00e1c3]" style="font-family: Lalezar, system-ui;">
                <input type="checkbox" @change="changeLeaderboard()" v-model="selectedLeaderboard"/>
                <div class="slider border border-white"></div>
                <span class="left-label" :class="selectedLeaderboard === false ? 'text-white' : 'text-black'">ACTIVE</span>
                <span class="right-label" :class="selectedLeaderboard === true ? 'text-white' : 'text-black'">ENDED</span>
              </label>
            </div>
          </div>
          <div class="flex justify-center mt-8 mb:mb-24">
            <table class="w-full lg:w-auto border-separate border-spacing-y-6">
              <thead v-motion-slide-visible-bottom class="rounded bg-[#121212] border border-[#20202E]" style="font-family: Russo One, system-ui;">
                <tr> 
                  <th
                    class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-2xl font-light text-left rounded-l">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-2xl font-light text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-2xl font-light text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-2xl font-light text-right rounded-r">
                    Prize
                  </th>
                </tr>
              </thead>
              <div class="mt-4"></div>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index" style="font-family: Russo One, system-ui" class="rounded bg-[#121212] border border-[#20202E]">
                  <td
                    class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[20px] font-bold text-left uppercase" style="font-family: Russo One, system-ui">
                      <div class="text-white tracking-[4px]">{{ getOrdinalSuffix(index + 4) }}</div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[20px] text-white font-bold text-center tracking-[4px]">
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[20px] text-white font-bold text-center tracking-[4px]">
                    {{ item.wagered }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[20px] text-white font-black text-right tracking-[4px]">
                    {{ getRewardValue(index + 3)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false"  class="block md:hidden">
          <div class="flex flex-col justify-center items-center gap-4">
            <div class="bg-gradient-to-r from-[#150033] via-[#461B83] to-[#150033] p-1 rounded-full">
              <div v-motion-slide-visible-bottom class="text-center text-lg text-white font-thin uppercase rounded-full px-10 py-3 bg-gradient-to-r from-[#121212] via-[#DC00B9] to-[#121212]" style="font-family: Lalezar, system-ui;">
                {{ selectedLeaderboard == false ? timer : "Leaderboard Ended" }}
              </div>
            </div>
            <ul v-if="showPrevLeaderboard == 'true'" class="flex flex-wrap gap-1 text-lg font-medium text-center text-gray-500" style="font-family: Lalezar, system-ui;;">
                <li @click="selectedLeaderboard = false; changeLeaderboard();" class="rounded-2xl border border-white cursor-pointer" :class="selectedLeaderboard === false ? 'bg-gradient-to-r from-[#150033] via-[#461B83] to-[#150033]' : ''">
                    <div class="inline-block px-4 py-3 text-white">Active</div>
                </li>
                <li @click="selectedLeaderboard = true; changeLeaderboard();" class="rounded-2xl border border-white cursor-pointer" :class="selectedLeaderboard === true ? 'bg-gradient-to-r from-[#150033] via-[#461B83] to-[#150033]' : ''">
                    <div class="inline-block px-4 py-3 text-white">Ended</div>
                </li>
            </ul>
          </div>
          <div class="flex justify-center mt-4 mb:mb-24 text-xl bg-black bg-opacity-40" style="font-family: Lalezar, system-ui">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index">
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-center">
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-center">
                    {{ item.wagered }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-right">
                    {{ getRewardValue(index)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <PrevLeaderboardSection ref="PrevLeaderboardSection"/> -->

</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  components: {
    // PrevLeaderboardSection
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      timer: '',
      selectedLeaderboard: false,

      currentLeaderboard: [],
      prevLeaderboard: [],
      showPrevLeaderboard: 'false',

      startDate: null,
      endDate: null,

      notFormattedEndDate: null,

      loading: false
    };
  },
  methods: {
    async init() {

      this.showPrevLeaderboard = process.env.VUE_APP_SHOW_PREVIOUS_LEADERBOARD;
      const baseUrl = process.env.VUE_APP_BASE_API;

      this.loading = true

        await axios.get(`${baseUrl}/api/stake/leaderboard`,{
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        }).then(res => {
              axios.get(`${baseUrl}/api/stake/leaderboard?previous=true`,{
                headers: {
                  'x-api-key': process.env.VUE_APP_X_API_KEY,
                },
              }).then(rest => {

                // const startDateSplit = res.data.startDate.split('-');
                // const endDateSplit = res.data.endDate.split('-');
              
                this.startDate = moment(res.data.startDate).format('MMMM DD, YYYY')
                this.endDate = moment(res.data.endDate).format('MMMM DD, YYYY')

                this.notFormattedEndDate = moment(res.data.endDate).format('YYYY-MM-DD')

                this.currentLeaderboard = res.data.leaderboard
                this.prevLeaderboard = rest.data.leaderboard

                this.changeLeaderboard()
                this.startTimer()

                this.loading = false
              });
        });
    },
    changeLeaderboard(){
       if(this.selectedLeaderboard == false){
            const leaderboard = this.currentLeaderboard
              this.top3 = leaderboard.slice(0, 3).map((item) => {
                return item;
              });

              this.topRest = leaderboard.slice(3, 30).map((item) => {
                return item;
              });

              this.topForMobile = leaderboard.slice(0, 30).map((item) => {
                return item;
            });
       }
       else{
          const leaderboard = this.prevLeaderboard
            this.top3 = leaderboard.slice(0, 3).map((item) => {
              return item;
            });

            this.topRest = leaderboard.slice(3, 20).map((item) => {
              return item;
            });

            this.topForMobile = leaderboard.slice(0, 20).map((item) => {
              return item;
          });
       }
    },
    maskString(str) {
      if (!str || str.length <= 4) return str;

      const start = str.slice(0, 2);
      const end = str.slice(-2);
      const middleAsterisks = '*'.repeat(str.length - 4);

      return `${start}${middleAsterisks}${end}`;
    },
    getOrdinalSuffix(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
      const prizes = [5000, 2400, 1000, 600, 400, 300, 250, 200, 175, 125, 110, 100, 90, 80, 70, 60, 50, 50, 40, 30, 20, 20, 20, 20, 20, 15, 15, 15, 15, 15];
      // if (rank >= 10 && rank < 15) {
      //   return '$'+ 20;
      // }
      // else if(rank >= 15){
      //   return '---'
      // }
      return '$'+ prizes[rank];
    },
    startTimer() {
      const getNextTargetDate = () => {
        const now = moment.tz('America/New_York');
        let targetDate = moment.tz(`${this.notFormattedEndDate}T00:00:00Z`, 'America/New_York').hour(19).minute(0).second(0)
        
        if (now.isAfter(targetDate)) {
          targetDate.add(1, 'week');
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment.tz('America/New_York');
        const distance = targetDate.diff(nowEst);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.timer = 'Leaderboard ended';
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
  },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 393px;
  height: 56px;
  background: linear-gradient(to right, #121212, #461B83 , #121212);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 4px;
  width: 192px;
  height: 48px;
  background: linear-gradient(to right, #121212, #461B83 , #121212);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(192px);
}

.toggle-switch span {
  font-size: 35px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
  -webkit-text-stroke: #B10BFF; 
  -webkit-text-stroke-width: 1px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 40px;
  margin-top: 5px;
  font-weight: 50;
}

.toggle-switch .right-label {
  position: absolute;
  right: 40px;
  margin-top: 5px;
  font-weight: 50;
}
</style>
